$navbar-brand-wrapper-mini-width: 70px;
$menu-break-point: 1020px;
$horizontal-navbar-height: 135px;
$horizontal-top-bg : #fff;
$horizontal-top-navbar-height: 60px;
$horizontal-menu-padding: 0 1rem;
$horizontal-menu-height:  57px;
$horizontal-top-menu-item-color: theme-color(dark);
$horizontal-top-font-size: .875rem;
$horizontal-bottom-bg: #ffffff;
$horizontal-bottom-menu-item-color: #001737;
$horizontal-bottom-menu-item-icon-color: theme-color(primary);
$horizontal-bottom-menu-item-active-color: color(orange);
$horizontal-menu-submenu-color: #202339;
$horizontal-bottom-menu-item-font-size: .875rem;
$horizontal-bottom-menu-item-hover-bg: transparent;
$horizontal-bottom-menu-item-hover-color: theme-color(primary); 
$horizontal-menu-item-padding: .75rem 0;
$horizontal-menu-icon-font-size: 1.25rem;
$horizontal-menu-submenu-item-padding: .85rem 0;
$horizontal-menu-box-shadow: 0 19px 34px -15px #ececec;
$navbar-pulse-shadow-start:0 0 0 0px rgba(65, 0, 96, 0.4);
$navbar-pulse-shadow-end:0 0 0 15px rgba(65, 0, 96, 0);